import { useState, useEffect } from "react";
import { Button, Menu } from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import css from "./style.module.scss";
import {
  EnvironmentOutlined,
  LoginOutlined,
  UserOutlined,
} from "@ant-design/icons";
import globalStore from "@globalStore";
import { observer } from "mobx-react-lite";
import LogoutOutlined from "@ant-design/icons/es/icons/LogoutOutlined";
import { LoginButton } from "./components/LoginButton";
import { WorkAreaButton } from "./components/WorkAreaButton";
import { ContextSelect } from "./components/ContextSelect";
import localStore from "../dataView/stores/localStore";

const { subjectStore, authStore, projectStore } = globalStore;

const Navbar = observer(() => {
  const [keys, setKeys] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    setKeys([location.pathname]);
  }, [location]);

  const navigation = [
    {
      label: <Link to="/">Главная</Link>,
      key: "/",
    },
    {
      label: !authStore.isLogged ? <WorkAreaButton text="Рабочая область"/> : <Link to="/data-view/demonstration" >Рабочая область</Link>,
      key: "/data-view/demonstration",
    },
    {
      type: "divider",
    },
    {
      label: <Link to="/applied-services">Сервисы</Link>,
      key: "/applied-services",
    },
    {
      label: <Link to="/about">Руководство</Link>,
      key: "/about",
    },
    {
      label: <ContextSelect />,
      key: "/context-select",
      icon: <EnvironmentOutlined />,
    },
    {
      label: <LoginButton />,
      key: "/account/info",
      icon: authStore.isLogged ? <UserOutlined /> : <LoginOutlined />,
      children: authStore.isLogged && [
        {
          label: <Link to="/account/projects">Проекты</Link>,
          key: "/account/projects",
        },
        {
          label: "Сценарии",
          key: "/account/scenarios",
          children:
            projectStore.isUserProjectsFetched &&
            projectStore.userProjects.map((project) => ({
              label: (
                <Link to={`/account/projects/${project.id}/demonstration`}>
                  {project.name}
                </Link>
              ),
              key: `/account/projects/${project.id}/demonstration`,
            })),
        },
        {
          label: <Link to="/account/layers">Слои</Link>,
          key: "/account/layers",
        },
        {
          label: <a onClick={async () => {
            await authStore.logout()
            navigate("/")
          }}>Выйти</a>,
          icon: <LogoutOutlined />,
        },
      ],
    },
  ];

  if (location.pathname === "/data-view/demonstration") {
    navigation.push({
      label: (
        <Button
          type="primary"
          onClick={() => {
            localStore.setArtificialAssistant(true)
            localStore.setFeatureActiveTab("artificialassistant")
            // localStore.setSelectedFeature(null)
          }}
          disabled={!authStore.isLogged || subjectStore.currentCity.id !== 1}
        >
          Ассистент
        </Button>
      ),
      key: "/ia-button",
    });
  }

  return (
    <Menu
      className={
        location.pathname === "/data-view/demonstration"
          ? css.containerWithArtifical
          : css.container
      }
      mode="horizontal"
      items={navigation}
      selectedKeys={keys}
    />
  );
});

export default Navbar;
