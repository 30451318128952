import axios from "axios";

const PutCityToInitialize = (cityId) => {
  return axios.put(
    `${process.env.REACT_APP_BLOCKSNET_INITIALIZE}/model/setup/${cityId}`
  );
};

const GetModelCityAll = () => {
  return axios.get(
    `${process.env.REACT_APP_BLOCKSNET_INITIALIZE}/model/setup/all`
  );
};

const GetInitializingModel = () => {
  return axios.get(
    `${process.env.REACT_APP_BLOCKSNET_INITIALIZE}/model/setup/current`
  );
};

export { GetModelCityAll, GetInitializingModel, PutCityToInitialize };
