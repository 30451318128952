import axios from "axios";
import {action, makeAutoObservable} from "mobx";

export interface Schema {
    fields: Field[]
}

export interface Field {
    name: string
    model: string
    description: string
    is_nullable: boolean
    is_editable: boolean
    is_required: boolean
    data_type: string

}

export interface FieldItem {
    name: string,
    isEditable: boolean,
    type: string,
    description: string
}

class SchemaStore {

    service: FieldItem[] = [];
    building: FieldItem[] = [];
    physical_object: FieldItem[] = [];
    isFetched: boolean = false;

    getSchema(schemaName: string){
        axios.get(`${process.env.REACT_APP_MAIN_API_NEW}/api/schema/${schemaName}`)
            .then(
                action('onSuccess', ({data}) => {
                    this.isFetched = true

                    const _data: Schema = data
                    const result = _data.fields.map(field => {
                        return {
                            name: field.name,
                            isEditable: field.is_editable,
                            type: field.data_type,
                            description: field.description
                        }
                    })

                    if (schemaName === 'service'){
                        this.service = result
                    }
                    if (schemaName === 'building'){
                        this.building = result
                    }
                    if (schemaName === 'physical_object'){
                        this.physical_object = result
                    }
            }),
                action('onError', (err: any)=>{
                    console.log(err)
                })
            )
    }

    constructor() {
        makeAutoObservable(this);
    }
}

export default new SchemaStore();
