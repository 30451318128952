export const getRandomColor = () => {
  const random = () => ({
    r: Math.floor(Math.random() * 256),
    g: Math.floor(Math.random() * 256),
    b: Math.floor(Math.random() * 256),
  });
  const luminance = ({ r, g, b }) => (0.299 * r + 0.587 * g + 0.114 * b) / 255;
  let randomColor = random();
  while (luminance(randomColor) > 0.5 || luminance(randomColor) < 0.25) {
    randomColor = random();
  }
  return `rgb(${randomColor.r}, ${randomColor.g}, ${randomColor.b})`;
};
