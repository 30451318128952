import { makeAutoObservable, when } from "mobx";
import { chillGreen } from "../utils/colorPalettes";
import { getRandomColor } from "../utils/randomColor";
import { fetchStatuses } from "./layerStore";

export class Style {
  layer = null;
  color = null;
  lineWidth = null;
  circleRadius = null;
  fillOpacity = null;
  zoom = [0, 22];
  gradient = null;

  get json() {
    return {
      color: this.color,
      lineWidth: this.lineWidth,
      circleRadius: this.circleRadius,
      fillOpacity: this.fillOpacity,
      minZoom: this.zoom[0],
      maxZoom: this.maxZoom[1],
      gradient: this.gradient.json,
    };
  }

  get minZoom() {
    return this.zoom[0];
  }

  get maxZoom() {
    return this.zoom[1];
  }

  setColor(value) {
    this.color = value;
  }

  setLineWidth(value) {
    this.lineWidth = value;
  }

  setCircleRadius(value) {
    this.circleRadius = value;
  }

  setFillOpacity(value) {
    this.fillOpacity = value;
  }

  setZoom([minZoom, maxZoom]) {
    this.zoom = [minZoom, maxZoom];
  }

  get cssColor() {
    if (!this.layer.fetchStatus === fetchStatuses.success) return this.color;
    if (this.gradient.property) return this.gradient.cssColor;
    else return this.color;
  }

  get colorExpression() {
    return [
      "case",
      ["boolean", ["feature-state", "select"], false],
      "#444444",
      ["boolean", ["feature-state", "hover"], false],
      "#666666",
      this.gradient.colorExpression,
    ];
  }

  constructor(
    layer,
    {
      color,
      lineWidth = 1,
      circleRadius = 5,
      fillOpacity = 0.1,
      minZoom = 0,
      maxZoom = 22,
      gradient = {},
    }
  ) {
    makeAutoObservable(this);
    this.layer = layer;
    Object.assign(this, {
      color,
      lineWidth,
      circleRadius,
      fillOpacity,
    });
    this.setZoom([minZoom, maxZoom]);
    if (gradient.property && !color) this.color = "#222222";
    if (!gradient.property && !color) this.color = getRandomColor();
    this.gradient = new Gradient(layer, gradient);
  }
}

export const valueTypes = {
  categorical: "Категориальные",
  continuous: "Непрерывные",
};

export class Gradient {
  layer = null;
  property = null;
  valueType = valueTypes.continuous;
  valueColors = {}; //value: color

  get json() {
    return {
      property: this.property,
      valueType: this.valueType,
      values: Object.keys(this.valueColors),
      colors: Object.values(this.valueColors),
    };
  }

  get sortedValueColors() {
    const tmp = Object.entries(this.valueColors);
    if (this.valueType === valueTypes.continuous) {
      return tmp.sort((a, b) => Number.parseFloat(a[0]) - Number.parseFloat(b[0]));
    } else return tmp;
  }

  autoFill() {
    let colors = {};
    if (this.valueType === valueTypes.continuous) {
      colors[this.layer.getMinPropertyValue(this.property)] = chillGreen[0];
      colors[this.layer.getMaxPropertyValue(this.property)] = chillGreen[1];
    } else {
      this.layer
        .getPropertyValues(this.property)
        .forEach((value) => (colors[value] = getRandomColor()));
    }
    this.valueColors = colors;
  }

  setProperty(property) {
    this.property = property;
    this.valueColors = {};
  }

  setValueType(valueType) {
    this.valueType = valueType;
    this.valueColors = {};
  }

  setValueColor(value, color) {
    this.valueColors[value] = color;
  }

  removeValue(value) {
    delete this.valueColors[value];
  }

  addValue(value) {
    this.valueColors[value] = getRandomColor();
  }

  get defaultColor() {
    return this.layer.style.color;
  }

  get cssColor() {
    let colors = Object.values(this.valueColors);
    let colorsString = colors
      .map((color, index) => `${color} ${(100.0 * index) / (colors.length - 1)}%`)
      .join(", ");
    return `linear-gradient(180deg, ${colorsString})`;
  }

  get colorExpression() {
    if (!this.property || this.sortedValueColors.length === 0) return this.defaultColor;
    const valueColors = this.sortedValueColors
      .map(([value, color]) => [
        this.valueType === valueTypes.continuous ? Number.parseFloat(value) : `${value}`,
        color,
      ])
      .flat();
    if (this.valueType === valueTypes.continuous) {
      return [
        "case",
        ["!=", ["get", this.property], null],
        ["interpolate-lab", ["linear"], ["number", ["get", this.property]], ...valueColors],
        this.defaultColor,
      ];
    } else {
      return ["match", ["to-string", ["get", this.property]], ...valueColors, this.defaultColor];
    }
  }

  constructor(
    layer,
    {
      property,
      valueType = valueTypes.continuous,
      values = [],
      colors = null,
      autoMin = null,
      autoMax = null,
    }
  ) {
    makeAutoObservable(this);
    this.layer = layer;
    this.property = property;
    this.valueType = valueType;
    when(
      () => layer.geojson,
      () => {
        let tmp = values.filter(() => true);
        if (valueType === valueTypes.categorical) {
          if (tmp.length === 0) return this.autoFill();
        } else {
          if (autoMin) tmp = [layer.getMinPropertyValue(property), ...tmp];
          if (autoMax) tmp = [...tmp, layer.getMaxPropertyValue(property)];
        }
        tmp.forEach(
          (value, index) =>
            (this.valueColors[value] = colors
              ? colors[index]
              : tmp.length === 2
              ? chillGreen[index]
              : getRandomColor())
        );
      }
    );
  }
}
