import { action, makeAutoObservable, reaction, toJS, when } from "mobx";
import { message } from "antd";
import { Style } from "./style";

export const fetchStatuses = {
  pending: "Загрузка",
  success: "Успех",
  error: "Ошибка",
};

export default class LayerStore {
  _idSeed = 0;
  localStore = null;
  _layerGroups = [];
  localization = {
    is_chart: "Графики",
    carid: "Идентификатор",
    station_type: "Тип станции",
    status: "Статус",
    station_coords_id: "ID координат",
  };
  groupDeleted = null;
  assistantGroup = null;
  customPolygonGroup = null;
  isDragGroup = false;
  dragCount = 0;

  getLayerGroupById(id) {
    return this._layerGroups.find(
      (layerGroup) => Number(layerGroup.id) === Number(id)
    );
  }

  getLayerById(id) {
    return this.layers.find((layer) => layer.id === id);
  }

  get layerGroups() {
    return this._layerGroups.slice().reverse();
  }

  setLayerGroups = (newLayerGroups) => {
    this.layerGroups = newLayerGroups;
  };

  get layers() {
    return this.layerGroups.map((layerGroup) => layerGroup.layers).flat();
  }

  get fetchedLayers() {
    return this.layers.filter(
      (layer) => layer.fetchStatus !== fetchStatuses.success
    );
  }

  clearLayerGroups() {
    this._layerGroups = [];
    this.assistantGroup = null;
    this.customPolygonGroup = null;
  }

  addLayerGroup(layerGroupJson) {
    const layerGroup = new LayerGroup(this, layerGroupJson);
    this._layerGroups.push(layerGroup);
    if(layerGroupJson.name === 'Ассистент') this.assistantGroup = layerGroup.id;
    if(layerGroupJson.name === 'Произвольный полигон') this.customPolygonGroup = layerGroup.id;
  }

  hideAll() {
    this._layerGroups.map(item => item.hide())
  }

  showAll() {
    this._layerGroups.map(item => item.show())
  }

  stopIsDragGroupe() {
    this.isDragGroup = false;
  }

  moveLayerGroup(dragKey, dropKey, dropPosition) {
    const dragGroup = this.layerGroups.find((group) => group.id === dragKey);
    const dropGroup = this.layerGroups.find((group) => group.id === dropKey);
    if (dragGroup && dropGroup) {
      const removedGroup = this.layerGroups.splice(
        this.layerGroups.indexOf(dragGroup),
        1
      )[0];

      let insertIndex;
      if (dropPosition === -1) {
        insertIndex = this.layerGroups.indexOf(dropGroup);
      } else {
        insertIndex = this.layerGroups.indexOf(dropGroup) + 1;
      }
      this.layerGroups.splice(insertIndex, 0, removedGroup);

      this.isDragGroup = true;
      this.dragCount++;
    }
  }

  removeLayerGroup(layerGroup) {
    this._layerGroups = this._layerGroups.filter((lg) => lg !== layerGroup);
    this.setGroupDeleted(layerGroup);
    if(layerGroup.id === this.assistantGroup) this.assistantGroup = null;
    if(layerGroup.id === this.customPolygonGroup) this.customPolygonGroup = null;
  }

  setGroupDeleted(groupDeleted) {
    this.groupDeleted = groupDeleted;
  }

  constructor(localStore) {
    makeAutoObservable(this);
    this.localStore = localStore;
    reaction(
      () => localStore.context,
      () => this.clearLayerGroups()
    );
  }
}

export class LayerGroup {
  static _idSeed = 0;
  _layerStore = null;
  _idSeed = 0;
  _layers = [];
  _id = null;
  name = null;
  isVisible = true; //is visible on the map
  isExpanded = true; //expanded tree node in tree component
  properties = null;
  propertiesFetchStatus = null;

  static _getId() {
    return this._idSeed++;
  }

  setName(name) {
    this.name = name;
  }

  toggleExpanded() {
    this.isExpanded = !this.isExpanded;
  }

  toggleVisibility() {
    this.isVisible = !this.isVisible;
  }

  hide() {
    this.isVisible = false;
  }

  show() {
    this.isVisible = true;
  }


  addLayer(layer) {
    this._layers.push(new Layer(this, layer))
  }


  get id() {
    return `${this._id}`;
  }

  get layers() {
    return this._layers;
  }

  constructor(layerStore, { name, propertiesPromise = null, layers }) {
    makeAutoObservable(this);
    this._layerStore = layerStore;
    this._id = LayerGroup._getId();
    this.name = name;
    this._layers = layers.map((layer) => new Layer(this, layer));
    if (propertiesPromise) {
      this.propertiesFetchStatus = fetchStatuses.pending;
      propertiesPromise.then(
        action((data) => {
          this.properties = data;
          this.propertiesFetchStatus = fetchStatuses.success;
        }),
        action(() => {
          this.propertiesFetchStatus = fetchStatuses.error;
          message.error(`Не удалось загрузить данные в наборе "${this.name}"`);
        })
      );
    }

    when(
      () => {
        const fetchedCount = this.layers.reduce(
          (acc, curr) =>
            curr.fetchStatus !== fetchStatuses.pending ? acc + 1 : acc,
          0
        );
        const layersCount = this.layers.length;
        return layersCount > 0 && fetchedCount === layersCount;
      },
      () => {
        const successCount = this.layers.reduce(
          (acc, curr) =>
            curr.fetchStatus === fetchStatuses.success ? acc + 1 : acc,
          0
        );
        const layersCount = this.layers.length;
        if (successCount === layersCount)
          message.success(`Набор "${this.name}" успешно загружен`);
        else message.error(`По параметрам "${this.name}" отсутствуют данные`);
        // else message.error(`Не удалось загрузить набор "${this.name}"`);
      }
    );
  }
}

export class Layer {
  static _idSeed = 0;
  _data = null;
  _filters = [];
  _id = null;
  style = null;
  name = null;
  isVisible = true;
  layerGroup = null;
  isWMS = false;
  isHeatmap = false;
  parkGenerated = "";
  fetchStatus = fetchStatuses.pending;
  error = null;

  setName(name) {
    if (name.length > 0) this.name = name;
  }

  static _getId() {
    return this._idSeed++;
  }
  get Style() {
    return this.style;
  }

  get filtersJson() {
    return this.filters.map((filter) => filter.json);
  }

  get file() {
    return new Blob([JSON.stringify(this.geojson)], { type: "text/plain" });
  }

  get tiles() {
    if (!this._data) return null;
    return [
      `${process.env.REACT_APP_GEOSERVER}/wms?bbox={bbox-epsg-3857}&format=image/png&service=WMS&version=1.1.1&request=GetMap&srs=EPSG:3857&transparent=true&width=256&height=256&layers=${this._data.name}`,
    ];
  }

  getPropertyValues(property) {
    let tmp = [];
    this.geojson.features.forEach((feature) => {
      const value = feature.properties ? feature.properties[property] : "";
      if (tmp.indexOf(value) < 0) tmp.push(value);
    });
    return tmp;
  }

  getMinPropertyValue(property) {
    const values = this.getPropertyValues(property)
      .map((value) => Number.parseFloat(value))
      .filter((value) => !Number.isNaN(value));
    const min = values.reduce(
      (acc, curr) => (curr < acc ? curr : acc),
      values[0]
    );
    return min ? min : 0;
  }

  getMaxPropertyValue(property) {
    const values = this.getPropertyValues(property)
      .map((value) => Number.parseFloat(value))
      .filter((value) => !Number.isNaN(value));
    const max = values.reduce(
      (acc, curr) => (curr > acc ? curr : acc),
      values[0]
    );
    return max ? max : 0;
  }

  addFilter(property, condition, value) {
    this._filters.push(new Filter({ property, condition, value }));
  }

  removeFilter(filter) {
    this._filters = this._filters.filter((fltr) => fltr !== filter);
  }

  toggleVisibility() {
    this.isVisible = !this.isVisible;
  }

  setVisibility(isVisible) {
    this.isVisible = isVisible;
  }

  get id() {
    return `${this.layerGroup.id}/${this._id}`;
  }

  get properties() {
    let properties = [];
    if (this.geojson.type === "Feature") {
      properties = Object.keys(this.geojson.properties);
    } else {
      this.geojson.features.forEach(
        (feature) =>
          feature.properties &&
          Object.keys(feature.properties).forEach((property) => {
            if (properties.indexOf(property) < 0) properties.push(property);
          })
      );
    }
    return properties.sort((a, b) => a.localeCompare(b));
  }

  get geojson() {
    return toJS(this._data);
  }

  get filters() {
    return this._filters;
  }

  constructor(
    layerGroup,
    {
      name,
      dataPromise,
      disabled,
      style = {},
      filters = [],
      interactive = null,
      isWMS,
      isHeatmap = false,
      parkGenerated,
      stylePromise = null,
      filtersPromise = null,
    }
  ) {
    makeAutoObservable(this);
    this._id = Layer._getId();
    this.name = name;
    this.layerGroup = layerGroup;
    this.isVisible = !disabled;
    this.style = new Style(this, style);
    if (stylePromise)
      stylePromise.then(
        action((promisedStyle) => (this.style = new Style(this, promisedStyle)))
      );
    if (filtersPromise)
      filtersPromise.then(
        action(
          (promisedFilters) =>
            (this._filters = promisedFilters.map(
              (filter) => new Filter(filter)
            ))
        )
      );
    this._filters = filters.map((filter) => new Filter(filter));
    this.isWMS = isWMS;
    this.isHeatmap = isHeatmap;
    this.parkGenerated = parkGenerated;
    if (interactive)
      this.interactive = {
        text: interactive.text,
        action: action((feature) => {
          switch (interactive.hideLayer) {
            case "origin":
              this.setVisibility(false);
              break;
            case "all":
              layerGroup.layers.forEach((layer) => layer.setVisibility(false));
              break;
            case "others":
              layerGroup.layers
                .filter((layer) => layer !== this)
                .forEach((layer) => layer.setVisibility(false));
              break;
            default:
              break;
          }
          const layers = interactive.layers;
          this.layerGroup.layers.push(
            ...layers.map(
              (layer) =>
                new Layer(this.layerGroup, {
                  name: layer.name,
                  style: layer.style,
                  dataPromise: layer.dataFetch(feature),
                  interactive: layer.interactive,
                })
            )
          );
        }),
      };
    dataPromise.then(
      action((data) => {
        this.fetchStatus = fetchStatuses.success;
        this._data = data;
      }),
      action((error) => {
        this.fetchStatus = fetchStatuses.error;
        this.error = error.message;
      })
    );
  }
}

export class Filter {
  property = null;
  condition = null;
  value = null;

  get json() {
    return {
      property: this.property,
      condition: this.condition,
      value: this.value,
    };
  }

  get expression() {
    return [this.condition, ["get", this.property], this.value];
  }

  constructor({ property, condition, value }) {
    Object.assign(this, { property, condition, value });
  }
}
