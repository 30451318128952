import { Spin, Layout } from "antd";
import React from "react";
const { Content } = Layout;

function LoadingSpinner() {
  return (
    <Content
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Spin
        size="large"
        // tip="Загрузка страницы..."
      />
    </Content>
  );
}

export default LoadingSpinner;
