import { useState } from "react";
import { Modal, Form, Input, Button, message } from "antd";
import { Link, useNavigate } from "react-router-dom";
import globalStore from "@globalStore";
import { observer } from "mobx-react-lite";
const { authStore } = globalStore;

export const WorkAreaButton = observer(({text}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const onCancel = () => {
    setIsModalVisible(false);
  };

  const onFinish = (values) => {
    setIsLoading(true);
    authStore.login(values.username, values.password).then(
      () => {
        message.success("Авторизация прошла успешно");
        setIsModalVisible(false);
        setIsLoading(false);
        navigate("/data-view/demonstration");
      },
      () => {
        message.error("Неверный логин или пароль");
        setIsLoading(false);
      }
    );
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      {authStore.isLogged ? (
        <Link to="/account">Аккаунт</Link>
      ) : (
        <a className={text === "Перейти к данным" && 'full-card__btn'} onClick={() => !authStore.isLogged && setIsModalVisible(true)}>{text}</a>
      )}
      <Modal title="Авторизация" open={isModalVisible} onCancel={onCancel} footer={null}>
        <Form
          preserve={false}
          name="basic"
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 15 }}
          labelAlign="right"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          size="large"
        >
          <Form.Item
            label="Логин"
            name="username"
            rules={[{ required: true, message: "Пожалуйста, введите логин" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Пароль"
            name="password"
            rules={[{ required: true, message: "Пожалуйста, введите пароль" }]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 8, span: 8 }}>
            <Button type="primary" htmlType="submit" loading={isLoading} style={{ width: "100%" }}>
              Войти
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
});
