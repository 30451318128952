import { action, makeAutoObservable, toJS } from "mobx";
import LayerStore from "./layerStore";
import globalStore from "@globalStore";
import schemaStore from "./schemaStore";
import axios from "axios";
const { subjectStore, projectStore } = globalStore;

class _LocalStore {
  layerStore = null;
  currentLayerIsWMS = false;
  selectedFeature = null;
  hoveredFeature = null;

  selectedSource = null;
  selectedLayerGroup = null;
  selectedLayer = null;
  selectedLayerName = "";
  showLayerInfo = false;
  municipalityTerritoryId = null;

  _availableCities = []; //список городов с доступными метриками

  mapMode = "simple_select";
  drawCallback = null;
  drawPolygonCallback = null;
  drawLineCallback = null;
  showAjaxNotification = null;
  ajaxNotificationType = "info";

  drawerPolygon = null;

  artificialAssistant = false;
  artificialAssistantHistory = false;
  artificialAssistantContext = "";
  artificialAssistantContextId = "";
  artificialAssistantChangedContextId = false;
  artificialAssistantLogsOpen = false;
  artificialAssistantLogs = [];

  blocksNetInitializing = false;
  blocksNetInitialized = false;
  blockForBocksNet = null;

  blockNetTrigger = false;
  setBlockNetTrigger(status) {
    this.blockNetTrigger = status;
  }

  objectNetServices = [];
  objectNetServiceEneble = false;

  message = [
    {
      id: 1,
      texts: [
        "Вы можете задать мне вопрос про стратегию социально-экономического развития города или про обеспеченность объектами обслуживания.",
      ],
      name: 'start'
    },
  ];

  setMessage(msg) {
    this.message = msg;
  }

  setStartMessage() {
    this.message = [
      {
        id: 1,
        texts: [
          "Вы можете задать мне вопрос про стратегию социально-экономического развития города или про обеспеченность объектами обслуживания.",
        ],
        name: 'start'
      },
    ];
  }

  tempDrawer = null;
  featureActiveTab = this.artificialAssistant ? "artificialassistant" : "properties";

  setTempDrawer(name) {
    this.tempDrawer = name;
  }

  setFeatureActiveTab(tab) {
    this.featureActiveTab = tab;
  }

  setBlocksNetInitializing(flag) {
    this.blocksNetInitializing = flag;
  }

  setBlocksNetInitialized(flag) {
    this.blocksNetInitialized = flag;
  }

  setBlockForBocksNet(block_id) {
    this.blockForBocksNet = block_id;
  }

  setMunicipalityTerritoryId(id) {
    this.municipalityTerritoryId = id;
  }

  setSelectedLayer(layer) {
    this.selectedLayer = layer;
  }

  setSelectedLayerName(layerName) {
    this.selectedLayerName = layerName;
  }

  setShowLayerInfo(flag) {
    this.showLayerInfo = flag;
  }

  setSelectedSource(source) {
    this.selectedSource = source;
  }

  setMapMode(mode) {
    this.mapMode = mode;
  }

  setDrawerPolygon(flag) {
    this.drawerPolygon = flag;
  }

  setDrawCallback(callback) {
    this.drawCallback = callback;
    this.mapMode = "draw_point";
  }

  setDrawPolygonCallback(callback) {
    this.drawPolygonCallback = callback;
    this.mapMode = "draw_polygon";
  }

  setDrawLineCallback(callback) {
    this.drawLineCallback = callback;
    this.mapMode = "draw_line_string";
  }

  setSelectedLayerGroup(layerGroup) {
    this.selectedLayerGroup = layerGroup;
  }

  setSelectedFeature(feature) {
    this.selectedFeature = feature;
  }

  setHoveredFeature(feature) {
    this.hoveredFeature = feature;
  }

  setToggleAjaxNotification(data, type) {
    this.showAjaxNotification = data;
    this.ajaxNotificationType = type;
  }

  setArtificialAssistant(flag) {
    this.artificialAssistant = flag;
  }

  setArtificialAssistantHistory(flag) {
    this.artificialAssistantHistory = flag;
  }

  setArtificialAssistantContext(context) {
    this.artificialAssistantContext = context;
  }

  setArtificialAssistantContextId(contextId) {
    this.artificialAssistantContextId = contextId;
  }

  setArtificialAssistantChangedContextId(flag) {
    this.artificialAssistantChangedContextId = flag;
  }

  setOpenArtificialAssistantLogs(flag) {
    this.artificialAssistantLogsOpen = flag;
  }

  setArtificialAssistantLogs(logs, prompt) {
    !!logs && !!prompt
      ? this.artificialAssistantLogs.push({ logs: logs, prompt: prompt })
      : (this.artificialAssistantLogs = []);
  }

  get isInitialized() {
    return this._availableCities;
  }

  get isMetricsAvailable() {
    console.log(subjectStore?.currentCity)
    return (
      subjectStore.currentCity && subjectStore.currentCity.code &&
      this._availableCities.indexOf(String(subjectStore.currentCity.id)) >= 0
    );
  }

  get metricsCityName() {
    return subjectStore.currentCity.code && subjectStore.currentCity.code;
  }

  isMetricAvailable() {
    return this.isMetricsAvailable;
  }

  get context() {
    if (projectStore.currentProject && projectStore.currentProject.isFetched)
      return {
        type: "project",
        object: projectStore.currentProject,
      };
    if (subjectStore.isFetched)
      return {
        type: "city",
        object: subjectStore.currentCity,
      };
    return null;
  }

  constructor() {
    makeAutoObservable(this);
    this.layerStore = new LayerStore(this);
    schemaStore.getSchema("service");
    schemaStore.getSchema("building");
    schemaStore.getSchema("physical_object");
    axios
      .get(`${process.env.REACT_APP_METRICS_ON}/api/objectnat/provision/all`)
      .then(action(({ data }) => (this._availableCities = Object.keys(data))));
  }
}

const LocalStore = new _LocalStore();
export default LocalStore;
